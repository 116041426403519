//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SecondaryButton',
  props: {
    text: {
      required: true,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean
    }
  },
}
