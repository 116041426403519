//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {VueSelect} from 'vue-select'
import 'vue-select/dist/vue-select.css'
import ImageUploader from '@/components/shared/image-uploader'
export default {
  name: 'CustomCombo',
  components: {ImageUploader, VueSelect},
  props: {
    value: {required: false},
    items: {
      required: true,
      type: Array,
    },
    itemValue: {
      required: false,
      type: String,
    },
    itemText: {
      required: false,
      type: String,
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    type: {
      required: false,
      type: String,
      default: 'text',
    },
    columns: {
      required: false,
      type: String,
    },
    rows: {
      required: false,
      type: String,
    },
    isMandatory: {
      required: false,
      type: Boolean,
    },
    ruleMessage: {
      required: false,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data: () => ({selected: [], wrapperClass: '', comboIsValid: true}),
  computed: {},
  watch: {
    value(value) {
      //  if (value === null) {
      this.selected = this.items.find((x) => x[this.itemValue] === value)
      // }
    },
  },
  created() {
    switch (this.columns) {
      case '2':
        this.wrapperClass = 'two-column-wrapper'
        break
      case '3':
        this.wrapperClass = 'three-column-wrapper'
        break
    }
    switch (this.rows) {
      case '1':
        this.wrapperClass = 'one-row-image-wrapper'
        break
      case '2':
        this.wrapperClass = 'two-row-image-wrapper'
        break
    }
  },
  mounted() {
    this.selected = this.items.find((x) => x[this.itemValue] === this.value)
  },
  methods: {
    remove(item) {
      this.values = this.values.filter((v) => v !== item)
    },
    getClass(checked) {
      return checked ? 'activeItem pl-7' : 'item pl-7'
    },
    valueChanged(object) {
      if (object !== null) {
        this.comboIsValid = true
        this.selected = object
        this.$emit(
          'input',
          this.multiple === false ? object[this.itemValue] : object
        )
        this.$emit('change')
      } else if (object === null) {
        this.comboIsValid = false
        this.$emit('input', null)
        this.selected = []
      }
    },
  },
}
