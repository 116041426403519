//
//
//
//
//
//

export default {
  name: 'PrimaryTitle',
}
