import { render, staticRenderFns } from "./primary-title.vue?vue&type=template&id=18fa10ef&scoped=true&"
import script from "./primary-title.vue?vue&type=script&lang=js&"
export * from "./primary-title.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18fa10ef",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VSubheader})
