//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloseBtn from '@/components/shared/close-btn'
import SecondaryButton from '@/components/shared/secondary-button'
import LabeledControlLayout from '@/components/shared/labeled-control-layout'
import CustomCombo from '@/components/shared/custom-combo'
import {mapActions, mapState} from 'vuex'

export default {
  name: 'SwitchUser',
  components: {
    CloseBtn,
    SecondaryButton,
    LabeledControlLayout,
    CustomCombo,
  },
  data() {
    return {
      userId: 0,
      customerId: 0,
    }
  },
  computed: {
    ...mapState('common/switchUser', ['users', 'customers']),
    ...mapState('backoffice/settings', ['adminSettings']),
    customerComboItems() {
      return this.customers.map((c) => {
        return {
          text: c.fullName ? c.fullName : c.companyName,
          value: c.id,
        }
      })
    },
    userComboItems() {
      return this.users.map((u) => {
        return {
          text: u.fullName ? u.fullName : u.email,
          value: u.id,
        }
      })
    },
    showAllCustomers() {
      const settingValue =
        this.getShowAllCustomersForSuperAdminUsers() === 'true'
      if (settingValue) this.fetchCustomers()
      else this.fetchCustomersWithActivePortalUsers()

      return settingValue
    },
  },
  methods: {
    ...mapActions('common/cache', ['loadingCache']),
    ...mapActions('common/switchUser', [
      'fetchUsers',
      'fetchCustomers',
      'fetchCustomersWithActivePortalUsers',
    ]),
    onClose() {
      this.$emit('close')
    },
    onSwitchButtonClick() {
      if (this.showAllCustomers) this.switchCustomer()
      else this.switchUser()
    },
    async switchCustomer() {
      const response = await this.$auth.loginWith('switchCustomer', {
        data: {
          id: this.$auth.user.id,
          customerId: this.customerId,
        },
      })
      if (response.status === 200 && response.data.token) {
        this.$toast.success('Switched customer!')
        window.location.href = '/my-dashboard'
      } else {
        this.$toast.error('Error while switching customer!')
      }
    },
    async switchUser() {
      const response = await this.$auth.loginWith('switchUser', {
        data: {
          id: this.userId,
        },
      })
      if (response.status === 200 && response.data.token) {
        this.$toast.success('Switched user!')
        window.location.href = '/my-dashboard'
      } else {
        this.$toast.error('Error while switching user!')
      }
    },
    selectUserIfOneItemInDropdown() {
      if (this.userComboItems.length === 1) {
        this.userId = this.userComboItems[0].value
      }
    },
    async customerChanged() {
      this.userId = 0
      await this.fetchUsers({customerId: this.customerId})
      this.selectUserIfOneItemInDropdown()
    },
    getShowAllCustomersForSuperAdminUsers() {
      return this.$store.state.backoffice.settings.adminSettings &&
        this.$store.state.backoffice.settings.adminSettings
          .show_all_customers_for_super_admin_users
        ? this.$store.state.backoffice.settings.adminSettings
            .show_all_customers_for_super_admin_users
        : false
    },
  },
  async created() {
    const show_all_customers_for_super_admin_users = this.getShowAllCustomersForSuperAdminUsers()

    if (show_all_customers_for_super_admin_users === 'true') {
      await this.fetchCustomers()
    } else {
      await this.fetchCustomersWithActivePortalUsers()
      this.customerId = this.customers[0].id
      await this.fetchUsers({customerId: this.customerId})
      this.selectUserIfOneItemInDropdown()
    }
  },
}
